import React, {Component} from 'react';
import {withRouter, WithRouterProps} from "../../hoc/withRouter";
import Form from "antd/lib/form";
import MainLayout from "../../components/MainLayout/MainLayout";
import "./Axieinfinity.css";
import {Button, Col, Input, Modal, Row} from "antd";
import {EditOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";

interface IParams {
    roninAddress: string;
}

interface IDashboardStates {
    processing: boolean;
    roninAddress: string;
    roninAddressTemp: string;
    dataAxie: any;
    dataTokenHoldings: any;
    dataExchangerate: any;
    modalChangeAddress: boolean;
}

type Props = WithRouterProps<IParams>;

class Dashboard extends Component<Props, IDashboardStates> {
    private readonly initState!: IDashboardStates;

    constructor(props: any) {
        super(props);
        const {match} = this.props;

        this.initState = {
            processing: true,
            roninAddress: match.params.roninAddress ?? "your ronin address",
            roninAddressTemp: match.params.roninAddress ?? "your ronin address",
            dataAxie: [],
            dataTokenHoldings: [],
            dataExchangerate: {},
            modalChangeAddress: false,
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        document.title = "N3FT.GAMES - Dashboard";
    }


    onFieldChange = <T extends keyof Pick<any, "roninAddressTemp">>(target: T, value: any) => {
        const currentState = {...this.state};
        switch (target) {
            case "roninAddressTemp":
                currentState.roninAddressTemp = value;
                break;
        }

        this.setState({
            ...currentState,
        });
    }

    openModalChangeAddress = () => {
        this.setState({
            modalChangeAddress: true,
        });
    }

    closeModalChangeAddress = () => {
        this.setState({
            modalChangeAddress: false,
        });
    }

    buttonChangeAddress = async () => {
        await this.setState({
            roninAddress: this.state.roninAddressTemp,
            modalChangeAddress: false,
        });
        await window.history.pushState("", "", `/axieinfinity/dashboard/${this.state.roninAddress}`);

    }

    render() {
        return (
            <React.Fragment>
                <MainLayout defaultSelectedKeys={['m-tracker']} roninAddress={this.state.roninAddress}>

                    <br/><span className={`h2Title`}>Dashboard</span> <span onClick={this.openModalChangeAddress} className={`roninwalletEditable`}>{this.state.roninAddress} <EditOutlined/> </span> <br/><br/>

                    <Row style={{marginTop: 14}}>
                        <Col xs={24} xl={5}>
                            <Link to={`/axieinfinity/my-assets/${this.state.roninAddress}`}>
                                <div className={`boxListProduct2`}>
                                    <img src={'../../assets/img/Asset_4.png'} className={`dashboardIconLogo`} alt="NftImage"/>
                                    <div className={`whiteCenter`}>Monitor Axieinfinity nft asset</div>
                                </div>
                            </Link>
                        </Col>
                        <Col xs={24} xl={5}>
                            <Link to={`/axieinfinity/battle-history/${this.state.roninAddress}`}>
                                <div className={`boxListProduct2`}>
                                    <img src={'../../assets/img/Asset_2.png'} className={`dashboardIconLogo`} alt="NftImage"/>
                                    <div className={`whiteCenter`}>Monitor Battle History</div>
                                </div>
                            </Link>
                        </Col>
                        <Col xs={24} xl={5}>
                            <Link to={`/axieinfinity/tracker/${this.state.roninAddress}`}>
                                <div className={`boxListProduct2`}>
                                    <img src={'../../assets/img/Asset_5.png'} className={`dashboardIconLogo`} alt="NftImage"/>
                                    <div className={`whiteCenter`}>Monitor Guild</div>
                                </div>
                            </Link>
                        </Col>
                        <Col xs={24} xl={5}>
                            <div className={`boxListProduct2`}>
                                <img src={'../../assets/img/Asset_soon.png'} className={`dashboardIconLogo`} alt="NftImage"/>
                                <div className={`whiteCenter`}>More Feature Coming Soon</div>
                            </div>
                        </Col>
                    </Row>

                </MainLayout>

                <Modal
                    visible={this.state.modalChangeAddress}
                    title={`Change Ronin Address`}
                    onOk={this.closeModalChangeAddress}
                    onCancel={this.closeModalChangeAddress}
                    // width={'80%'}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button key="back" type="primary" onClick={this.buttonChangeAddress}>Update</Button>,
                        <Button key="back" type="primary" onClick={this.closeModalChangeAddress}>Close</Button>,
                    ]}
                >
                    <Form.Item>
                        <div className={`text-small-grey`}>Ronin Address</div>
                        <Input
                            value={this.state.roninAddressTemp}
                            placeholder="Ronin:xx"
                            onChange={event => this.onFieldChange("roninAddressTemp", event.target.value)}
                        />
                    </Form.Item>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(Dashboard);
