import React, {FunctionComponent} from "react";
import {Col, Row, Tag} from "antd";
import {VideoCameraOutlined} from "@ant-design/icons";
import moment from "moment";

export const AxieBattleHistory: FunctionComponent<any> = (props: any) => {
    let newClientElo = 0;
    let winStatus = "";
    let mmrGain = 0;
    let oldOpponentElo = 0;
    let slpGain = 0;

    const duration = moment.duration(moment(props.dataHistory?.game_ended).diff(moment(props.dataHistory?.game_started)));
    const minutesDuration = parseInt(String(duration.asMinutes())) % 60;
    const secondDuration = parseInt(String(duration.asSeconds())) % 60;

    if(props.dataHistory?.winner === "draw"){
        winStatus = "DRAW"
    }else{
        winStatus = (props.dataHistory?.winner === props.roninAddress?.replace('ronin:', '0x')) ? "WIN" : "LOSE";
    }

    let listAxieClient = [];
    let listAxieOpponent = [];
    if (props.dataHistory.first_client_id === props.roninAddress?.replace('ronin:', '0x')) {
        listAxieClient = props.dataHistory?.first_team_fighters;
        listAxieOpponent = props.dataHistory?.second_team_fighters;
        newClientElo = props.dataHistory?.eloAndItem[0]?.new_elo;
        mmrGain = newClientElo - props.dataHistory?.eloAndItem[0]?.old_elo;
    } else {
        listAxieClient = props.dataHistory?.second_team_fighters;
        listAxieOpponent = props.dataHistory?.first_team_fighters;
        newClientElo = props.dataHistory?.eloAndItem[1]?.new_elo;
        mmrGain = newClientElo - props.dataHistory?.eloAndItem[1]?.old_elo;
    }

    return (
        <Row style={{paddingBottom: 7}}>
            <Col xs={24} xl={5}>
                <span className={`nftValueConvert`}>Result:</span> <b className={`text${winStatus}`}>{winStatus}</b><br/>
                <span className={`nftValueConvert`}>Rank:</span> <span className={`textWhite90`}>{newClientElo} (<b className={`text${winStatus}`}>{(mmrGain > 0) ? "+" : ""}{mmrGain}</b>)</span> <br/>
                <span className={`nftValueConvert`}>Time:</span> <span className={`textWhite90`}>{moment(props.dataHistory?.game_started).format("YYYY-MM-DD HH:mm")}</span> <br/>
                <span className={`nftValueConvert`}>Duration:</span> <span className={`textWhite90`}>{minutesDuration}</span> <span className={`nftValueConvert`}>min</span> <span className={`textWhite90`}>{secondDuration}</span> <span className={`nftValueConvert`}>sec</span>
            </Col>
            <Col xs={24} xl={14}>
                <img src={`https://assets.axieinfinity.com/axies/${listAxieClient[0]}/axie/axie-full-transparent.png`} className={`battleAxieImage`} alt="NftImage"/>
                <img src={`https://assets.axieinfinity.com/axies/${listAxieClient[1]}/axie/axie-full-transparent.png`} className={`battleAxieImage`} alt="NftImage"/>
                <img src={`https://assets.axieinfinity.com/axies/${listAxieClient[2]}/axie/axie-full-transparent.png`} className={`battleAxieImage`} alt="NftImage"/>
                &nbsp; &nbsp; <span className={`textWhite90`}>VS</span> &nbsp; &nbsp;
                <img src={`https://assets.axieinfinity.com/axies/${listAxieOpponent[0]}/axie/axie-full-transparent.png`} className={`battleAxieImage`} alt="NftImage"/>
                <img src={`https://assets.axieinfinity.com/axies/${listAxieOpponent[1]}/axie/axie-full-transparent.png`} className={`battleAxieImage`} alt="NftImage"/>
                <img src={`https://assets.axieinfinity.com/axies/${listAxieOpponent[2]}/axie/axie-full-transparent.png`} className={`battleAxieImage`} alt="NftImage"/>
            </Col>
            <Col xs={24} xl={5}>
                <Tag icon={<VideoCameraOutlined/>} color="#404950" style={{marginTop: 25, cursor: "pointer"}}>
                    Watch Replay
                </Tag>
            </Col>
        </Row>
    )
}
