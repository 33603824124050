import axios from "axios";

class AuthServices {
    private axios = axios.create({
        baseURL: "https://webhook.site/47e4d1a1-5482-4eb8-ad12-1999d3a8ad4e",
    });

    public async register(inputData?: any) {
        try {
            return await this.axios.post<any>('/v1/register/guild',
                inputData, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": ""
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }
}

export const authServices = new AuthServices();
