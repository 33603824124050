import React, {Component} from 'react';
import {withRouter, WithRouterProps} from "../../hoc/withRouter";
import Form from "antd/lib/form";
import MainLayout from "../../components/MainLayout/MainLayout";
import "./Axieinfinity.css";
import {Button, Col, Input, Modal, Row} from "antd";
import {battleHistoryService} from "../../services/axieinfinity/BattleHistoryService";
import {EditOutlined} from "@ant-design/icons";
import {AxieBattleHistory} from "../../components/Tracker/AxieBattleHistory";
import {AxieMostUsedBattleTeam} from "../../components/Tracker/AxieMostUsedBattleTeam";
import {eloService} from "../../services/axieinfinity/EloService";

interface IParams {
    roninAddress: string;
}

interface IBattleHistoryStates {
    processing: boolean;
    roninAddress: string;
    roninAddressTemp: string;
    dataAxie: any;
    dataBattleHistory: any;
    eloData: any;
    modalChangeAddress: boolean;
}

type Props = WithRouterProps<IParams>;

class BattleHistory extends Component<Props, IBattleHistoryStates> {
    private readonly initState!: IBattleHistoryStates;

    constructor(props: any) {
        super(props);
        const {match} = this.props;

        this.initState = {
            processing: true,
            roninAddress: match.params.roninAddress ?? "your ronin address",
            roninAddressTemp: match.params.roninAddress ?? "your ronin address",
            dataAxie: [],
            dataBattleHistory: [],
            modalChangeAddress: false,
            eloData: [],
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        document.title = "N3FT.GAMES - Battle History";
        this.getBattleHistory();
        this.getElo();
    }

    async getBattleHistory() {
        try {
            await battleHistoryService.getBattleHistory({roninAddress: this.state.roninAddress})
                .then((r) => {
                    this.setState({
                        dataBattleHistory: r?.battles
                    });
                });
        } catch (e) {
            console.log(e)
        }
    }

    async getElo() {
        try {
            await eloService.getElo({roninAddress: this.state.roninAddress})
                .then((r) => {
                    console.log(r[0]?.items[0]);
                    this.setState({
                        eloData: r[0]?.items[0]
                    });
                });
        } catch (e) {
            console.log(e)
        }
    }

    onFieldChange = async <T extends keyof Pick<any, "roninAddressTemp">>(target: T, value: any) => {
        const currentState = {...this.state};
        switch (target) {
            case "roninAddressTemp":
                currentState.roninAddressTemp = value;
                break;
        }

        await this.setState({
            ...currentState,
        });
    }

    openModalChangeAddress = () => {
        this.setState({
            modalChangeAddress: true,
        });
    }

    closeModalChangeAddress = () => {
        this.setState({
            modalChangeAddress: false,
        });
    }

    buttonChangeAddress = async () => {
        await this.setState({
            roninAddress: this.state.roninAddressTemp,
            modalChangeAddress: false,
        });
        await window.history.pushState("", "", `/axieinfinity/battle-history/${this.state.roninAddress}`);
        this.getBattleHistory();
        this.getElo();
    }

    render() {
        return (
            <React.Fragment>
                <MainLayout defaultSelectedKeys={['m-tracker']} roninAddress={this.state.roninAddress}>

                    <br/><span className={`h2Title`}>Battle History</span> <span onClick={this.openModalChangeAddress} className={`roninwalletEditable`}>{this.state.roninAddress} <EditOutlined/> </span> <br/><br/>

                    <Row style={{marginTop: 14}}>
                        <AxieMostUsedBattleTeam
                            roninAddress={this.state.roninAddress}
                            battleHistory={this.state.dataBattleHistory}
                            elo={this.state.eloData.elo ?? "-"}
                            rank={this.state.eloData.rank ?? "-"}
                        />
                        <Col xs={24} xl={24} style={{marginTop: 20}}>
                            <div className={`boxListProduct3`}>
                                {
                                    this.state.dataBattleHistory?.slice(0, 10).map((item: any, i: number) =>
                                        <div key={i} className={`insideBoxListProduct`}>
                                            <AxieBattleHistory roninAddress={this.state.roninAddress} dataHistory={item}/>
                                        </div>
                                    )
                                }
                            </div>
                        </Col>
                    </Row>

                </MainLayout>

                <Modal
                    visible={this.state.modalChangeAddress}
                    title={`Change Ronin Address`}
                    onOk={this.closeModalChangeAddress}
                    onCancel={this.closeModalChangeAddress}
                    // width={'80%'}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button key="back" type="primary" onClick={this.buttonChangeAddress}>Update</Button>,
                        <Button key="back" type="primary" onClick={this.closeModalChangeAddress}>Close</Button>,
                    ]}
                >
                    <Form.Item>
                        <div className={`text-small-grey`}>Ronin Address</div>
                        <Input
                            value={this.state.roninAddressTemp}
                            placeholder="Ronin:xx"
                            onChange={event => this.onFieldChange("roninAddressTemp", event.target.value)}
                        />
                    </Form.Item>
                </Modal>

            </React.Fragment>
        );
    }
}

export default withRouter(BattleHistory);
