const countryList: any = [
    {
        "countryCode": "93",
        "isoCode": "AF",
        "isoCodes": "AFG",
        "countryName": "Afghanistan",
    },
    {
        "countryCode": "355",
        "isoCode": "AL",
        "isoCodes": "ALB",
        "countryName": "Albania",
    },
    {
        "countryCode": "213",
        "isoCode": "DZ",
        "isoCodes": "DZA",
        "countryName": "Algeria",
    },
    {
        "countryCode": "376",
        "isoCode": "AD",
        "isoCodes": "AND",
        "countryName": "Andorra",
    },
    {
        "countryCode": "244",
        "isoCode": "AO",
        "isoCodes": "AGO",
        "countryName": "Angola",
    },
    {
        "countryCode": "672",
        "isoCode": "AQ",
        "isoCodes": "ATA",
        "countryName": "Antarctica",
    },
    {
        "countryCode": "54",
        "isoCode": "AR",
        "isoCodes": "ARG",
        "countryName": "Argentina",
    },
    {
        "countryCode": "374",
        "isoCode": "AM",
        "isoCodes": "ARM",
        "countryName": "Armenia",
    },
    {
        "countryCode": "297",
        "isoCode": "AW",
        "isoCodes": "ABW",
        "countryName": "Aruba",
    },
    {
        "countryCode": "61",
        "isoCode": "AU",
        "isoCodes": "AUS",
        "countryName": "Australia",
    },
    {
        "countryCode": "43",
        "isoCode": "AT",
        "isoCodes": "AUT",
        "countryName": "Austria",
    },
    {
        "countryCode": "994",
        "isoCode": "AZ",
        "isoCodes": "AZE",
        "countryName": "Azerbaijan",
    },
    {
        "countryCode": "973",
        "isoCode": "BH",
        "isoCodes": "BHR",
        "countryName": "Bahrain",
    },
    {
        "countryCode": "880",
        "isoCode": "BD",
        "isoCodes": "BGD",
        "countryName": "Bangladesh",
    },
    {
        "countryCode": "375",
        "isoCode": "BY",
        "isoCodes": "BLR",
        "countryName": "Belarus",
    },
    {
        "countryCode": "32",
        "isoCode": "BE",
        "isoCodes": "BEL",
        "countryName": "Belgium",
    },
    {
        "countryCode": "501",
        "isoCode": "BZ",
        "isoCodes": "BLZ",
        "countryName": "Belize",
    },
    {
        "countryCode": "229",
        "isoCode": "BJ",
        "isoCodes": "BEN",
        "countryName": "Benin",
    },
    {
        "countryCode": "975",
        "isoCode": "BT",
        "isoCodes": "BTN",
        "countryName": "Bhutan",
    },
    {
        "countryCode": "591",
        "isoCode": "BO",
        "isoCodes": "BOL",
        "countryName": "Bolivia",
    },
    {
        "countryCode": "387",
        "isoCode": "BA",
        "isoCodes": "BIH",
        "countryName": "Bosnia and Herzegovina",
    },
    {
        "countryCode": "267",
        "isoCode": "BW",
        "isoCodes": "BWA",
        "countryName": "Botswana",
    },
    {
        "countryCode": "55",
        "isoCode": "BR",
        "isoCodes": "BRA",
        "countryName": "Brazil",
    },
    {
        "countryCode": "246",
        "isoCode": "IO",
        "isoCodes": "IOT",
        "countryName": "British Indian Ocean Territory",
    },
    {
        "countryCode": "673",
        "isoCode": "BN",
        "isoCodes": "BRN",
        "countryName": "Brunei",
    },
    {
        "countryCode": "359",
        "isoCode": "BG",
        "isoCodes": "BGR",
        "countryName": "Bulgaria",
    },
    {
        "countryCode": "226",
        "isoCode": "BF",
        "isoCodes": "BFA",
        "countryName": "Burkina Faso",
    },
    {
        "countryCode": "257",
        "isoCode": "BI",
        "isoCodes": "BDI",
        "countryName": "Burundi",
    },
    {
        "countryCode": "855",
        "isoCode": "KH",
        "isoCodes": "KHM",
        "countryName": "Cambodia",
    },
    {
        "countryCode": "237",
        "isoCode": "CM",
        "isoCodes": "CMR",
        "countryName": "Cameroon",
    },
    {
        "countryCode": "1",
        "isoCode": "CA",
        "isoCodes": "CAN",
        "countryName": "Canada",
    },
    {
        "countryCode": "238",
        "isoCode": "CV",
        "isoCodes": "CPV",
        "countryName": "Cape Verde",
    },
    {
        "countryCode": "236",
        "isoCode": "CF",
        "isoCodes": "CAF",
        "countryName": "Central African Republic",
    },
    {
        "countryCode": "235",
        "isoCode": "TD",
        "isoCodes": "TCD",
        "countryName": "Chad",
    },
    {
        "countryCode": "56",
        "isoCode": "CL",
        "isoCodes": "CHL",
        "countryName": "Chile",
    },
    {
        "countryCode": "86",
        "isoCode": "CN",
        "isoCodes": "CHN",
        "countryName": "China",
    },
    {
        "countryCode": "61",
        "isoCode": "CX",
        "isoCodes": "CXR",
        "countryName": "Christmas Island",
    },
    {
        "countryCode": "61",
        "isoCode": "CC",
        "isoCodes": "CCK",
        "countryName": "Cocos Islands",
    },
    {
        "countryCode": "57",
        "isoCode": "CO",
        "isoCodes": "COL",
        "countryName": "Colombia",
    },
    {
        "countryCode": "269",
        "isoCode": "KM",
        "isoCodes": "COM",
        "countryName": "Comoros",
    },
    {
        "countryCode": "682",
        "isoCode": "CK",
        "isoCodes": "COK",
        "countryName": "Cook Islands",
    },
    {
        "countryCode": "506",
        "isoCode": "CR",
        "isoCodes": "CRI",
        "countryName": "Costa Rica",
    },
    {
        "countryCode": "385",
        "isoCode": "HR",
        "isoCodes": "HRV",
        "countryName": "Croatia",
    },
    {
        "countryCode": "53",
        "isoCode": "CU",
        "isoCodes": "CUB",
        "countryName": "Cuba",
    },
    {
        "countryCode": "599",
        "isoCode": "CW",
        "isoCodes": "CUW",
        "countryName": "Curacao",
    },
    {
        "countryCode": "357",
        "isoCode": "CY",
        "isoCodes": "CYP",
        "countryName": "Cyprus",
    },
    {
        "countryCode": "420",
        "isoCode": "CZ",
        "isoCodes": "CZE",
        "countryName": "Czech Republic",
    },
    {
        "countryCode": "243",
        "isoCode": "CD",
        "isoCodes": "COD",
        "countryName": "Democratic Republic of the Congo",
    },
    {
        "countryCode": "45",
        "isoCode": "DK",
        "isoCodes": "DNK",
        "countryName": "Denmark",
    },
    {
        "countryCode": "253",
        "isoCode": "DJ",
        "isoCodes": "DJI",
        "countryName": "Djibouti",
    },
    {
        "countryCode": "670",
        "isoCode": "TL",
        "isoCodes": "TLS",
        "countryName": "East Timor",
    },
    {
        "countryCode": "593",
        "isoCode": "EC",
        "isoCodes": "ECU",
        "countryName": "Ecuador",
    },
    {
        "countryCode": "20",
        "isoCode": "EG",
        "isoCodes": "EGY",
        "countryName": "Egypt",
    },
    {
        "countryCode": "503",
        "isoCode": "SV",
        "isoCodes": "SLV",
        "countryName": "El Salvador",
    },
    {
        "countryCode": "240",
        "isoCode": "GQ",
        "isoCodes": "GNQ",
        "countryName": "Equatorial Guinea",
    },
    {
        "countryCode": "291",
        "isoCode": "ER",
        "isoCodes": "ERI",
        "countryName": "Eritrea",
    },
    {
        "countryCode": "372",
        "isoCode": "EE",
        "isoCodes": "EST",
        "countryName": "Estonia",
    },
    {
        "countryCode": "251",
        "isoCode": "ET",
        "isoCodes": "ETH",
        "countryName": "Ethiopia",
    },
    {
        "countryCode": "500",
        "isoCode": "FK",
        "isoCodes": "FLK",
        "countryName": "Falkland Islands",
    },
    {
        "countryCode": "298",
        "isoCode": "FO",
        "isoCodes": "FRO",
        "countryName": "Faroe Islands",
    },
    {
        "countryCode": "679",
        "isoCode": "FJ",
        "isoCodes": "FJI",
        "countryName": "Fiji",
    },
    {
        "countryCode": "358",
        "isoCode": "FI",
        "isoCodes": "FIN",
        "countryName": "Finland",
    },
    {
        "countryCode": "33",
        "isoCode": "FR",
        "isoCodes": "FRA",
        "countryName": "France",
    },
    {
        "countryCode": "689",
        "isoCode": "PF",
        "isoCodes": "PYF",
        "countryName": "French Polynesia",
    },
    {
        "countryCode": "241",
        "isoCode": "GA",
        "isoCodes": "GAB",
        "countryName": "Gabon",
    },
    {
        "countryCode": "220",
        "isoCode": "GM",
        "isoCodes": "GMB",
        "countryName": "Gambia",
    },
    {
        "countryCode": "995",
        "isoCode": "GE",
        "isoCodes": "GEO",
        "countryName": "Georgia",
    },
    {
        "countryCode": "49",
        "isoCode": "DE",
        "isoCodes": "DEU",
        "countryName": "Germany",
    },
    {
        "countryCode": "233",
        "isoCode": "GH",
        "isoCodes": "GHA",
        "countryName": "Ghana",
    },
    {
        "countryCode": "350",
        "isoCode": "GI",
        "isoCodes": "GIB",
        "countryName": "Gibraltar",
    },
    {
        "countryCode": "30",
        "isoCode": "GR",
        "isoCodes": "GRC",
        "countryName": "Greece",
    },
    {
        "countryCode": "299",
        "isoCode": "GL",
        "isoCodes": "GRL",
        "countryName": "Greenland",
    },
    {
        "countryCode": "502",
        "isoCode": "GT",
        "isoCodes": "GTM",
        "countryName": "Guatemala",
    },
    {
        "countryCode": "224",
        "isoCode": "GN",
        "isoCodes": "GIN",
        "countryName": "Guinea",
    },
    {
        "countryCode": "245",
        "isoCode": "GW",
        "isoCodes": "GNB",
        "countryName": "Guinea-Bissau",
    },
    {
        "countryCode": "592",
        "isoCode": "GY",
        "isoCodes": "GUY",
        "countryName": "Guyana",
    },
    {
        "countryCode": "509",
        "isoCode": "HT",
        "isoCodes": "HTI",
        "countryName": "Haiti",
    },
    {
        "countryCode": "504",
        "isoCode": "HN",
        "isoCodes": "HND",
        "countryName": "Honduras",
    },
    {
        "countryCode": "852",
        "isoCode": "HK",
        "isoCodes": "HKG",
        "countryName": "Hong Kong",
    },
    {
        "countryCode": "36",
        "isoCode": "HU",
        "isoCodes": "HUN",
        "countryName": "Hungary",
    },
    {
        "countryCode": "354",
        "isoCode": "IS",
        "isoCodes": "ISL",
        "countryName": "Iceland",
    },
    {
        "countryCode": "91",
        "isoCode": "IN",
        "isoCodes": "IND",
        "countryName": "India",
    },
    {
        "countryCode": "62",
        "isoCode": "ID",
        "isoCodes": "IDN",
        "countryName": "Indonesia",
    },
    {
        "countryCode": "98",
        "isoCode": "IR",
        "isoCodes": "IRN",
        "countryName": "Iran",
    },
    {
        "countryCode": "964",
        "isoCode": "IQ",
        "isoCodes": "IRQ",
        "countryName": "Iraq",
    },
    {
        "countryCode": "353",
        "isoCode": "IE",
        "isoCodes": "IRL",
        "countryName": "Ireland",
    },
    {
        "countryCode": "972",
        "isoCode": "IL",
        "isoCodes": "ISR",
        "countryName": "Israel",
    },
    {
        "countryCode": "39",
        "isoCode": "IT",
        "isoCodes": "ITA",
        "countryName": "Italy",
    },
    {
        "countryCode": "225",
        "isoCode": "CI",
        "isoCodes": "CIV",
        "countryName": "Ivory Coast",
    },
    {
        "countryCode": "81",
        "isoCode": "JP",
        "isoCodes": "JPN",
        "countryName": "Japan",
    },
    {
        "countryCode": "962",
        "isoCode": "JO",
        "isoCodes": "JOR",
        "countryName": "Jordan",
    },
    {
        "countryCode": "7",
        "isoCode": "KZ",
        "isoCodes": "KAZ",
        "countryName": "Kazakhstan",
    },
    {
        "countryCode": "254",
        "isoCode": "KE",
        "isoCodes": "KEN",
        "countryName": "Kenya",
    },
    {
        "countryCode": "686",
        "isoCode": "KI",
        "isoCodes": "KIR",
        "countryName": "Kiribati",
    },
    {
        "countryCode": "383",
        "isoCode": "XK",
        "isoCodes": "XKX",
        "countryName": "Kosovo",
    },
    {
        "countryCode": "965",
        "isoCode": "KW",
        "isoCodes": "KWT",
        "countryName": "Kuwait",
    },
    {
        "countryCode": "996",
        "isoCode": "KG",
        "isoCodes": "KGZ",
        "countryName": "Kyrgyzstan",
    },
    {
        "countryCode": "856",
        "isoCode": "LA",
        "isoCodes": "LAO",
        "countryName": "Laos",
    },
    {
        "countryCode": "371",
        "isoCode": "LV",
        "isoCodes": "LVA",
        "countryName": "Latvia",
    },
    {
        "countryCode": "961",
        "isoCode": "LB",
        "isoCodes": "LBN",
        "countryName": "Lebanon",
    },
    {
        "countryCode": "266",
        "isoCode": "LS",
        "isoCodes": "LSO",
        "countryName": "Lesotho",
    },
    {
        "countryCode": "231",
        "isoCode": "LR",
        "isoCodes": "LBR",
        "countryName": "Liberia",
    },
    {
        "countryCode": "218",
        "isoCode": "LY",
        "isoCodes": "LBY",
        "countryName": "Libya",
    },
    {
        "countryCode": "423",
        "isoCode": "LI",
        "isoCodes": "LIE",
        "countryName": "Liechtenstein",
    },
    {
        "countryCode": "370",
        "isoCode": "LT",
        "isoCodes": "LTU",
        "countryName": "Lithuania",
    },
    {
        "countryCode": "352",
        "isoCode": "LU",
        "isoCodes": "LUX",
        "countryName": "Luxembourg",
    },
    {
        "countryCode": "853",
        "isoCode": "MO",
        "isoCodes": "MAC",
        "countryName": "Macau",
    },
    {
        "countryCode": "389",
        "isoCode": "MK",
        "isoCodes": "MKD",
        "countryName": "Macedonia",
    },
    {
        "countryCode": "261",
        "isoCode": "MG",
        "isoCodes": "MDG",
        "countryName": "Madagascar",
    },
    {
        "countryCode": "265",
        "isoCode": "MW",
        "isoCodes": "MWI",
        "countryName": "Malawi",
    },
    {
        "countryCode": "60",
        "isoCode": "MY",
        "isoCodes": "MYS",
        "countryName": "Malaysia",
    },
    {
        "countryCode": "960",
        "isoCode": "MV",
        "isoCodes": "MDV",
        "countryName": "Maldives",
    },
    {
        "countryCode": "223",
        "isoCode": "ML",
        "isoCodes": "MLI",
        "countryName": "Mali",
    },
    {
        "countryCode": "356",
        "isoCode": "MT",
        "isoCodes": "MLT",
        "countryName": "Malta",
    },
    {
        "countryCode": "692",
        "isoCode": "MH",
        "isoCodes": "MHL",
        "countryName": "Marshall Islands",
    },
    {
        "countryCode": "222",
        "isoCode": "MR",
        "isoCodes": "MRT",
        "countryName": "Mauritania",
    },
    {
        "countryCode": "230",
        "isoCode": "MU",
        "isoCodes": "MUS",
        "countryName": "Mauritius",
    },
    {
        "countryCode": "262",
        "isoCode": "YT",
        "isoCodes": "MYT",
        "countryName": "Mayotte",
    },
    {
        "countryCode": "52",
        "isoCode": "MX",
        "isoCodes": "MEX",
        "countryName": "Mexico",
    },
    {
        "countryCode": "691",
        "isoCode": "FM",
        "isoCodes": "FSM",
        "countryName": "Micronesia",
    },
    {
        "countryCode": "373",
        "isoCode": "MD",
        "isoCodes": "MDA",
        "countryName": "Moldova",
    },
    {
        "countryCode": "377",
        "isoCode": "MC",
        "isoCodes": "MCO",
        "countryName": "Monaco",
    },
    {
        "countryCode": "976",
        "isoCode": "MN",
        "isoCodes": "MNG",
        "countryName": "Mongolia",
    },
    {
        "countryCode": "382",
        "isoCode": "ME",
        "isoCodes": "MNE",
        "countryName": "Montenegro",
    },
    {
        "countryCode": "212",
        "isoCode": "MA",
        "isoCodes": "MAR",
        "countryName": "Morocco",
    },
    {
        "countryCode": "258",
        "isoCode": "MZ",
        "isoCodes": "MOZ",
        "countryName": "Mozambique",
    },
    {
        "countryCode": "95",
        "isoCode": "MM",
        "isoCodes": "MMR",
        "countryName": "Myanmar",
    },
    {
        "countryCode": "264",
        "isoCode": "NA",
        "isoCodes": "NAM",
        "countryName": "Namibia",
    },
    {
        "countryCode": "674",
        "isoCode": "NR",
        "isoCodes": "NRU",
        "countryName": "Nauru",
    },
    {
        "countryCode": "977",
        "isoCode": "NP",
        "isoCodes": "NPL",
        "countryName": "Nepal",
    },
    {
        "countryCode": "31",
        "isoCode": "NL",
        "isoCodes": "NLD",
        "countryName": "Netherlands",
    },
    {
        "countryCode": "599",
        "isoCode": "AN",
        "isoCodes": "ANT",
        "countryName": "Netherlands Antilles",
    },
    {
        "countryCode": "687",
        "isoCode": "NC",
        "isoCodes": "NCL",
        "countryName": "New Caledonia",
    },
    {
        "countryCode": "64",
        "isoCode": "NZ",
        "isoCodes": "NZL",
        "countryName": "New Zealand",
    },
    {
        "countryCode": "505",
        "isoCode": "NI",
        "isoCodes": "NIC",
        "countryName": "Nicaragua",
    },
    {
        "countryCode": "227",
        "isoCode": "NE",
        "isoCodes": "NER",
        "countryName": "Niger",
    },
    {
        "countryCode": "234",
        "isoCode": "NG",
        "isoCodes": "NGA",
        "countryName": "Nigeria",
    },
    {
        "countryCode": "683",
        "isoCode": "NU",
        "isoCodes": "NIU",
        "countryName": "Niue",
    },
    {
        "countryCode": "850",
        "isoCode": "KP",
        "isoCodes": "PRK",
        "countryName": "North Korea",
    },
    {
        "countryCode": "47",
        "isoCode": "NO",
        "isoCodes": "NOR",
        "countryName": "Norway",
    },
    {
        "countryCode": "968",
        "isoCode": "OM",
        "isoCodes": "OMN",
        "countryName": "Oman",
    },
    {
        "countryCode": "92",
        "isoCode": "PK",
        "isoCodes": "PAK",
        "countryName": "Pakistan",
    },
    {
        "countryCode": "680",
        "isoCode": "PW",
        "isoCodes": "PLW",
        "countryName": "Palau",
    },
    {
        "countryCode": "970",
        "isoCode": "PS",
        "isoCodes": "PSE",
        "countryName": "Palestine",
    },
    {
        "countryCode": "507",
        "isoCode": "PA",
        "isoCodes": "PAN",
        "countryName": "Panama",
    },
    {
        "countryCode": "675",
        "isoCode": "PG",
        "isoCodes": "PNG",
        "countryName": "Papua New Guinea",
    },
    {
        "countryCode": "595",
        "isoCode": "PY",
        "isoCodes": "PRY",
        "countryName": "Paraguay",
    },
    {
        "countryCode": "51",
        "isoCode": "PE",
        "isoCodes": "PER",
        "countryName": "Peru",
    },
    {
        "countryCode": "63",
        "isoCode": "PH",
        "isoCodes": "PHL",
        "countryName": "Philippines",
    },
    {
        "countryCode": "64",
        "isoCode": "PN",
        "isoCodes": "PCN",
        "countryName": "Pitcairn",
    },
    {
        "countryCode": "48",
        "isoCode": "PL",
        "isoCodes": "POL",
        "countryName": "Poland",
    },
    {
        "countryCode": "351",
        "isoCode": "PT",
        "isoCodes": "PRT",
        "countryName": "Portugal",
    },
    {
        "countryCode": "974",
        "isoCode": "QA",
        "isoCodes": "QAT",
        "countryName": "Qatar",
    },
    {
        "countryCode": "242",
        "isoCode": "CG",
        "isoCodes": "COG",
        "countryName": "Republic of the Congo",
    },
    {
        "countryCode": "262",
        "isoCode": "RE",
        "isoCodes": "REU",
        "countryName": "Reunion",
    },
    {
        "countryCode": "40",
        "isoCode": "RO",
        "isoCodes": "ROU",
        "countryName": "Romania",
    },
    {
        "countryCode": "7",
        "isoCode": "RU",
        "isoCodes": "RUS",
        "countryName": "Russia",
    },
    {
        "countryCode": "250",
        "isoCode": "RW",
        "isoCodes": "RWA",
        "countryName": "Rwanda",
    },
    {
        "countryCode": "590",
        "isoCode": "BL",
        "isoCodes": "BLM",
        "countryName": "Saint Barthelemy",
    },
    {
        "countryCode": "290",
        "isoCode": "SH",
        "isoCodes": "SHN",
        "countryName": "Saint Helena",
    },
    {
        "countryCode": "590",
        "isoCode": "MF",
        "isoCodes": "MAF",
        "countryName": "Saint Martin",
    },
    {
        "countryCode": "508",
        "isoCode": "PM",
        "isoCodes": "SPM",
        "countryName": "Saint Pierre and Miquelon",
    },
    {
        "countryCode": "685",
        "isoCode": "WS",
        "isoCodes": "WSM",
        "countryName": "Samoa",
    },
    {
        "countryCode": "378",
        "isoCode": "SM",
        "isoCodes": "SMR",
        "countryName": "San Marino",
    },
    {
        "countryCode": "239",
        "isoCode": "ST",
        "isoCodes": "STP",
        "countryName": "Sao Tome and Principe",
    },
    {
        "countryCode": "966",
        "isoCode": "SA",
        "isoCodes": "SAU",
        "countryName": "Saudi Arabia",
    },
    {
        "countryCode": "221",
        "isoCode": "SN",
        "isoCodes": "SEN",
        "countryName": "Senegal",
    },
    {
        "countryCode": "381",
        "isoCode": "RS",
        "isoCodes": "SRB",
        "countryName": "Serbia",
    },
    {
        "countryCode": "248",
        "isoCode": "SC",
        "isoCodes": "SYC",
        "countryName": "Seychelles",
    },
    {
        "countryCode": "232",
        "isoCode": "SL",
        "isoCodes": "SLE",
        "countryName": "Sierra Leone",
    },
    {
        "countryCode": "65",
        "isoCode": "SG",
        "isoCodes": "SGP",
        "countryName": "Singapore",
    },
    {
        "countryCode": "421",
        "isoCode": "SK",
        "isoCodes": "SVK",
        "countryName": "Slovakia",
    },
    {
        "countryCode": "386",
        "isoCode": "SI",
        "isoCodes": "SVN",
        "countryName": "Slovenia",
    },
    {
        "countryCode": "677",
        "isoCode": "SB",
        "isoCodes": "SLB",
        "countryName": "Solomon Islands",
    },
    {
        "countryCode": "252",
        "isoCode": "SO",
        "isoCodes": "SOM",
        "countryName": "Somalia",
    },
    {
        "countryCode": "27",
        "isoCode": "ZA",
        "isoCodes": "ZAF",
        "countryName": "South Africa",
    },
    {
        "countryCode": "82",
        "isoCode": "KR",
        "isoCodes": "KOR",
        "countryName": "South Korea",
    },
    {
        "countryCode": "211",
        "isoCode": "SS",
        "isoCodes": "SSD",
        "countryName": "South Sudan",
    },
    {
        "countryCode": "34",
        "isoCode": "ES",
        "isoCodes": "ESP",
        "countryName": "Spain",
    },
    {
        "countryCode": "94",
        "isoCode": "LK",
        "isoCodes": "LKA",
        "countryName": "Sri Lanka",
    },
    {
        "countryCode": "249",
        "isoCode": "SD",
        "isoCodes": "SDN",
        "countryName": "Sudan",
    },
    {
        "countryCode": "597",
        "isoCode": "SR",
        "isoCodes": "SUR",
        "countryName": "Suriname",
    },
    {
        "countryCode": "47",
        "isoCode": "SJ",
        "isoCodes": "SJM",
        "countryName": "Svalbard and Jan Mayen",
    },
    {
        "countryCode": "268",
        "isoCode": "SZ",
        "isoCodes": "SWZ",
        "countryName": "Swaziland",
    },
    {
        "countryCode": "46",
        "isoCode": "SE",
        "isoCodes": "SWE",
        "countryName": "Sweden",
    },
    {
        "countryCode": "41",
        "isoCode": "CH",
        "isoCodes": "CHE",
        "countryName": "Switzerland",
    },
    {
        "countryCode": "963",
        "isoCode": "SY",
        "isoCodes": "SYR",
        "countryName": "Syria",
    },
    {
        "countryCode": "886",
        "isoCode": "TW",
        "isoCodes": "TWN",
        "countryName": "Taiwan",
    },
    {
        "countryCode": "992",
        "isoCode": "TJ",
        "isoCodes": "TJK",
        "countryName": "Tajikistan",
    },
    {
        "countryCode": "255",
        "isoCode": "TZ",
        "isoCodes": "TZA",
        "countryName": "Tanzania",
    },
    {
        "countryCode": "66",
        "isoCode": "TH",
        "isoCodes": "THA",
        "countryName": "Thailand",
    },
    {
        "countryCode": "228",
        "isoCode": "TG",
        "isoCodes": "TGO",
        "countryName": "Togo",
    },
    {
        "countryCode": "690",
        "isoCode": "TK",
        "isoCodes": "TKL",
        "countryName": "Tokelau",
    },
    {
        "countryCode": "676",
        "isoCode": "TO",
        "isoCodes": "TON",
        "countryName": "Tonga",
    },
    {
        "countryCode": "216",
        "isoCode": "TN",
        "isoCodes": "TUN",
        "countryName": "Tunisia",
    },
    {
        "countryCode": "90",
        "isoCode": "TR",
        "isoCodes": "TUR",
        "countryName": "Turkey",
    },
    {
        "countryCode": "993",
        "isoCode": "TM",
        "isoCodes": "TKM",
        "countryName": "Turkmenistan",
    },
    {
        "countryCode": "688",
        "isoCode": "TV",
        "isoCodes": "TUV",
        "countryName": "Tuvalu",
    },
    {
        "countryCode": "256",
        "isoCode": "UG",
        "isoCodes": "UGA",
        "countryName": "Uganda",
    },
    {
        "countryCode": "380",
        "isoCode": "UA",
        "isoCodes": "UKR",
        "countryName": "Ukraine",
    },
    {
        "countryCode": "971",
        "isoCode": "AE",
        "isoCodes": "ARE",
        "countryName": "United Arab Emirates",
    },
    {
        "countryCode": "44",
        "isoCode": "GB",
        "isoCodes": "GBR",
        "countryName": "United Kingdom",
    },
    {
        "countryCode": "1",
        "isoCode": "US",
        "isoCodes": "USA",
        "countryName": "United States",
    },
    {
        "countryCode": "598",
        "isoCode": "UY",
        "isoCodes": "URY",
        "countryName": "Uruguay",
    },
    {
        "countryCode": "998",
        "isoCode": "UZ",
        "isoCodes": "UZB",
        "countryName": "Uzbekistan",
    },
    {
        "countryCode": "678",
        "isoCode": "VU",
        "isoCodes": "VUT",
        "countryName": "Vanuatu",
    },
    {
        "countryCode": "379",
        "isoCode": "VA",
        "isoCodes": "VAT",
        "countryName": "Vatican",
    },
    {
        "countryCode": "58",
        "isoCode": "VE",
        "isoCodes": "VEN",
        "countryName": "Venezuela",
    },
    {
        "countryCode": "84",
        "isoCode": "VN",
        "isoCodes": "VNM",
        "countryName": "Vietnam",
    },
    {
        "countryCode": "681",
        "isoCode": "WF",
        "isoCodes": "WLF",
        "countryName": "Wallis and Futuna",
    },
    {
        "countryCode": "212",
        "isoCode": "EH",
        "isoCodes": "ESH",
        "countryName": "Western Sahara",
    },
    {
        "countryCode": "967",
        "isoCode": "YE",
        "isoCodes": "YEM",
        "countryName": "Yemen",
    },
    {
        "countryCode": "260",
        "isoCode": "ZM",
        "isoCodes": "ZMB",
        "countryName": "Zambia",
    },
    {
        "countryCode": "263",
        "isoCode": "ZW",
        "isoCodes": "ZWE",
        "countryName": "Zimbabwe",
    }
];

export {countryList}
