import React, {FunctionComponent} from "react";
import {Col, Row, Spin} from "antd";

export const AxieMostUsedBattleTeam: FunctionComponent<any> = (props: any) => {
    let processing = true;
    let mostUsedTeam = [];
    let totalWin = 0;
    let totalDraw = 0;
    let totalLose = 0;
    if (props?.battleHistory?.length > 0) {
        // NOT COMPLATE
        for (const item of props?.battleHistory) {
            let listAxieClient = [];
            if (item.first_client_id === props.roninAddress?.replace('ronin:', '0x')) {
                listAxieClient = item?.first_team_fighters;
            } else {
                listAxieClient = item?.second_team_fighters;
            }
            mostUsedTeam = listAxieClient;
            processing = false;

            if (item.winner === "draw") {
                totalDraw++;
            } else {
                if (item.winner === props.roninAddress?.replace('ronin:', '0x')) {
                    totalWin++;
                } else {
                    totalLose++;
                }
            }
        }
    }

    return (
        <React.Fragment>
            <Col xs={24} xl={12}>
                <Row>
                    <Col xs={24} xl={12}>
                        <div className={`boxListProduct3`}>
                            <span className={`title1`}>Matchmaking Rating:</span><br/>
                            <div className={`title2`}>{props?.elo}</div>
                        </div>
                    </Col>
                    <Col xs={24} xl={12}>
                        <div className={`boxListProduct3`}>
                            <span className={`title1`}>Rangking:</span><br/>
                            <div className={`title2`}>{props?.rank}</div>
                        </div>
                    </Col>
                    <Col xs={24} xl={24}>
                        <div className={`boxListProduct3`} style={{padding: 15}}>
                            <div style={{padding: 3}}>
                                <span className={`title1`}>Total Win: </span> <span className={`title1-2`}>{totalWin}</span>
                            </div>
                            <div style={{padding: 3}}>
                                <span className={`title1`}>Total Lose: </span> <span className={`title1-2`}>{totalLose}</span>
                            </div>
                            <div style={{padding: 3}}>
                                <span className={`title1`}>Total Draw: </span> <span className={`title1-2`}>{totalDraw}</span>
                            </div>
                            <div style={{padding: 3}}>
                                <span className={`title1`}>WinRate: </span> <span className={`title1-2`}>{ Number(totalWin / (totalWin + totalLose + totalDraw) * 100)} %</span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col xs={24} xl={12}>
                <div className={`boxListProduct3`}>
                    <span className={`title1`}>Most used team:</span><br/><br/>

                    <div style={{display: (processing) ? 'block' : 'none'}} className={`spinCenter`}>
                        <Spin style={{marginTop: 20, textAlign: "center"}} size={'large'} tip="Processing..."/>
                    </div>

                    <div style={{display: (!processing) ? 'block' : 'none'}}>
                        <Row style={{marginTop: 32}}>
                            <Col span={8}>
                                <img src={`https://assets.axieinfinity.com/axies/${mostUsedTeam[0]}/axie/axie-full-transparent.png`} className={`mostUsedAxie`} alt="NftImage"/>
                            </Col>
                            <Col span={8}>
                                <img src={`https://assets.axieinfinity.com/axies/${mostUsedTeam[1]}/axie/axie-full-transparent.png`} className={`mostUsedAxie`} alt="NftImage"/>
                            </Col>
                            <Col span={8}>
                                <img src={`https://assets.axieinfinity.com/axies/${mostUsedTeam[2]}/axie/axie-full-transparent.png`} className={`mostUsedAxie`} alt="NftImage"/>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>
        </React.Fragment>
    );
}
