import React, {FunctionComponent} from "react";
import {Col, Row} from "antd";
import numberFormat from "../../helper/numberFormat";

export const TrackerResume: FunctionComponent<any> = (props: any) => {
    console.log(props.data);
    let totalUnclaimedSlp = 0;
    let managerSlp = 0;
    let scholarSlp = 0;
    let totalAxie = 0;
    let roninSlp = 0;
    for (const item of props.data) {
       totalUnclaimedSlp += item?.inGameSlp ?? 0;
       managerSlp += item?.managerSlp ?? 0;
       scholarSlp += item?.scholarSlp ?? 0;
       totalAxie += item?.axies ?? 0;
       roninSlp += item?.roninSlp ?? 0;
    }


    return (
        <React.Fragment>
            <Col xs={24} xl={18}>
                <Row>
                    <Col xs={24} xl={8}>
                        <div className={`boxListProduct3`}>
                            <span className={`title1`}>Total Unclaimed SLP:</span><br/>
                            <div className={`title2`}>{numberFormat(totalUnclaimedSlp)}</div>
                        </div>
                    </Col>
                    <Col xs={24} xl={8}>
                        <div className={`boxListProduct3`}>
                            <span className={`title1`}>Manager:</span><br/>
                            <div className={`title2`}>{numberFormat(managerSlp)}</div>
                        </div>
                    </Col>
                    <Col xs={24} xl={8}>
                        <div className={`boxListProduct3`}>
                            <span className={`title1`}>Scholar:</span><br/>
                            <div className={`title2`}>{numberFormat(scholarSlp)}</div>
                        </div>
                    </Col>
                    <Col xs={24} xl={8}>
                        <div className={`boxListProduct3`}>
                            <span className={`title1`}>Scholar Account:</span><br/>
                            <div className={`title2`}>{numberFormat(props?.data?.length ?? 0)}</div>
                        </div>
                    </Col>
                    <Col xs={24} xl={8}>
                        <div className={`boxListProduct3`}>
                            <span className={`title1`}>Total Axie:</span><br/>
                            <div className={`title2`}>{numberFormat(totalAxie)}</div>
                        </div>
                    </Col>
                    <Col xs={24} xl={8}>
                        <div className={`boxListProduct3`}>
                            <span className={`title1`}>SLP in Game + in Ronin:</span><br/>
                            <div className={`title2`}>{numberFormat(totalUnclaimedSlp + roninSlp)}</div>
                        </div>
                    </Col>
                </Row>
            </Col>

            <Col xs={24} xl={6}>
                <Row>
                    <Col xs={24} xl={24}>
                        <div className={`boxListProduct4`}>
                            <span className={`title1`}>Resume:</span><br/>
                            <div className={`title2`}>ComingSoon</div>
                        </div>
                    </Col>
                </Row>
            </Col>
        </React.Fragment>
    );
}
