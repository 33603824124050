const ClassConfig: any = {
    Beast: {
        logo: "axieinfinity/class_beast.png",
        logo_white: "axieinfinity/class_beast_white.png",
        color: "#ffb812",
    },
    Aquatic: {
        logo: "axieinfinity/class_aquatic.png",
        logo_white: "axieinfinity/class_aquatic_white.png",
        color: "#00b8ce",
    },
    Plant: {
        logo: "axieinfinity/class_plant.png",
        logo_white: "axieinfinity/class_plant_white.png",
        color: "#6cc000",
    },
    Bug: {
        logo: "axieinfinity/class_bug.png",
        logo_white: "axieinfinity/class_bug_white.png",
        color: "#ff5341",
    },
    Bird: {
        logo: "axieinfinity/class_bird.png",
        logo_white: "axieinfinity/class_bird_white.png",
        color: "#ff8bbd",
    },
    Reptile: {
        logo: "axieinfinity/class_reptile.png",
        logo_white: "axieinfinity/class_reptile_white.png",
        color: "#dc8be4",
    },
    Mech: {
        logo: "axieinfinity/class_mech.png",
        logo_white: "axieinfinity/class_mech_white.png",
        color: "#c6bdd4",
    },
    Dawn: {
        logo: "axieinfinity/class_dawn.png",
        logo_white: "axieinfinity/class_dawn_white.png",
        color: "#beceff",
    },
    Dusk: {
        logo: "axieinfinity/class_dusk.png",
        logo_white: "axieinfinity/class_dusk_white.png",
        color: "#129092",
    }
}
export default ClassConfig;
