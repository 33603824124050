import React, {Component} from 'react';
import {withRouter, WithRouterProps} from "../../hoc/withRouter";
import Form from "antd/lib/form";
import MainLayout from "../../components/MainLayout/MainLayout";
import "./Axieinfinity.css";
import {Button, Col, Empty, Input, Modal, Row, Spin} from "antd";
import {axieInfinityComService} from "../../services/axieinfinity/AxieInfinityComService";
import {axieDataService} from "../../services/axieinfinity/AxieDataService";
import {roninChainService} from "../../services/axieinfinity/RoninChainService";
import {TokenHoldingVertical} from "../../components/Tracker/TokenHoldingVertical";
import {AxieHolding} from "../../components/Tracker/AxieHolding";
import {EditOutlined} from "@ant-design/icons";

interface IParams {
    roninAddress: string;
}

interface IMyNftAssetsStates {
    processing: boolean;
    roninAddress: string;
    roninAddressTemp: string;
    dataAxie: any;
    dataTokenHoldings: any;
    dataExchangerate: any;
    modalChangeAddress: boolean;
}

type Props = WithRouterProps<IParams>;

class MyNftAssets extends Component<Props, IMyNftAssetsStates> {
    private readonly initState!: IMyNftAssetsStates;

    constructor(props: any) {
        super(props);
        const {match} = this.props;

        this.initState = {
            processing: true,
            roninAddress: match.params.roninAddress ?? "your ronin address",
            roninAddressTemp: match.params.roninAddress ?? "your ronin address",
            dataAxie: [],
            dataTokenHoldings: [],
            dataExchangerate: {},
            modalChangeAddress: false,
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        document.title = "N3FT.GAMES - My Assets";
        await this.getExchangeRate();
        await this.getListAxie();
        await this.getTokenHoldings();
    }

    async getExchangeRate() {
        try {
            await axieInfinityComService.getExchangeRate({})
                .then((r) => {
                    this.setState({
                        dataExchangerate: r
                    })
                });
        } catch (e) {
            console.log(e)
        }
    }

    async getListAxie() {
        try {
            await axieDataService.getListAxieByAddress({
                roninAddress: this.state?.roninAddress
            }).then((r) => {
                this.setState({
                    processing: false,
                    dataAxie: r?.data
                })
            });
        } catch (e) {
            console.log(e)
        }
    }

    async getTokenHoldings() {
        try {
            await roninChainService.getTokenHoldings({
                roninAddress: this.state?.roninAddress
            }).then((r) => {
                this.setState({
                    dataTokenHoldings: r?.data
                })
            });
        } catch (e) {
            console.log(e)
        }
    }

    onFieldChange = <T extends keyof Pick<any, "roninAddressTemp">>(target: T, value: any) => {
        const currentState = {...this.state};
        switch (target) {
            case "roninAddressTemp":
                currentState.roninAddressTemp = value;
                break;
        }

        this.setState({
            ...currentState,
        });
    }

    openModalChangeAddress = () => {
        this.setState({
            modalChangeAddress: true,
        });
    }

    closeModalChangeAddress = () => {
        this.setState({
            modalChangeAddress: false,
        });
    }

    buttonChangeAddress = async () => {
        await this.setState({
            roninAddress: this.state.roninAddressTemp,
            modalChangeAddress: false,
        });
        await window.history.pushState("", "", `/axieinfinity/my-assets/${this.state.roninAddress}`);
        await this.getListAxie();
        await this.getTokenHoldings();
    }

    render() {
        return (
            <React.Fragment>
                <MainLayout defaultSelectedKeys={['m-tracker']} roninAddress={this.state.roninAddress}>

                    <br/><span className={`h2Title`}>My NFT Assets</span> <span onClick={this.openModalChangeAddress} className={`roninwalletEditable`}>{this.state.roninAddress} <EditOutlined/> </span> <br/><br/>

                    <Row>
                        <Col xs={24} xl={6}>
                            <TokenHoldingVertical
                                tokenData={this.state.dataTokenHoldings}
                                dataExchangerate={this.state.dataExchangerate}
                            />
                        </Col>
                        <Col xs={24} xl={18}>

                            <div style={{display: (this.state.processing) ? 'block' : 'none'}} className={`spinCenter`}>
                                <Spin style={{marginTop: 20, textAlign: "center"}} size={'large'} tip="Processing..."/>
                            </div>

                            <div style={{display: (!this.state.processing) ? 'block' : 'none'}}>
                                <div style={{display: (this.state.dataAxie.length < 1) ? 'block' : 'none'}}>
                                    <Empty/>
                                </div>
                                <div style={{display: (this.state.dataAxie.length > 0) ? 'block' : 'none', marginTop: 70}}>
                                    <Row style={{marginTop: 14}}>
                                        {
                                            this.state.dataAxie.map((item: any, i: number) =>
                                                <Col xs={24} xl={6} key={i}>
                                                    <div className={`boxListProduct2`}>
                                                        <a href={`https://marketplace.axieinfinity.com/axie/${item?.id}/`} target={`_blank`} style={{textDecoration: "none"}}>
                                                            <AxieHolding
                                                                axieId={item?.id}
                                                                axieName={item?.name}
                                                                axieClass={item?.class}
                                                                axieImage={item?.image}
                                                                axieBreedCount={item?.breedCount}
                                                            />
                                                        </a>
                                                    </div>
                                                </Col>
                                            )
                                        }
                                    </Row>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </MainLayout>

                <Modal
                    visible={this.state.modalChangeAddress}
                    title={`Change Ronin Address`}
                    onOk={this.closeModalChangeAddress}
                    onCancel={this.closeModalChangeAddress}
                    // width={'80%'}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button key="back" type="primary" onClick={this.buttonChangeAddress}>Update</Button>,
                        <Button key="back" type="primary" onClick={this.closeModalChangeAddress}>Close</Button>,
                    ]}
                >
                    <Form.Item>
                        <div className={`text-small-grey`}>Ronin Address</div>
                        <Input
                            value={this.state.roninAddressTemp}
                            placeholder="Ronin:xx"
                            onChange={event => this.onFieldChange("roninAddressTemp", event.target.value)}
                        />
                    </Form.Item>
                </Modal>

            </React.Fragment>
        );
    }
}

export default withRouter(MyNftAssets);
