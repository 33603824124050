import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Button, Col, Form, Input, Layout, Modal, Row, Typography} from "antd";
import "./Guest.less";

interface IGuestStates {
    roninAddress: string;
}

class Guest extends Component<any, IGuestStates> {
    private readonly initState!: IGuestStates;

    constructor(props: any) {
        super(props);

        this.initState = {
            roninAddress: "",
        }
        this.state = {...this.initState}
    }

    onFieldChange = <T extends keyof Pick<any, "roninAddress">>(target: T, value: any) => {
        const currentState = {...this.state};
        switch (target) {
            case "roninAddress":
                currentState.roninAddress = value;
                break;
        }

        this.setState({
            ...currentState,
        });
    }

    onClickSearch = async () => {
        if(!this.state.roninAddress || this.state.roninAddress.length !== 46 || ( this.state.roninAddress.substring(0, 6) !== "ronin:" )){
            Modal.error({
                title: 'Error',
                content: 'Please input valid ronin address'
            });
            return;
        }

        window.location.replace(`/axieinfinity/dashboard/${this.state.roninAddress}`);
    }

    componentDidMount() {
        document.title = "N3FT.GAMES - Guest";
    }

    render() {
        return (
            <Layout>
                <Layout.Content>
                    <div id={"sign-up-body"}>
                        <Row id="sign-up-wrapper">
                            <Col xl={12} lg={12} md={24} sm={24} xs={24} id={"sign-up-left-side"}>
                                <Row>
                                    <Col xxl={{span: 17, offset: 4}} xl={{span: 20, offset: 2}}>
                                        <Link to={`/`}>
                                            <img className={`header-logo`} src={'assets/img/n3ftlogo.png'} alt={`n3ft-logo`}/>
                                        </Link>

                                        <div id={"text-left-side-wrapper"}>
                                            <Typography.Title level={1}>
                                                Track your NFT assets.
                                            </Typography.Title>
                                        </div>
                                        <br/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl={12} lg={12} md={24} sm={24} xs={24} id={"sign-up-right-side"}>
                                <Row>
                                    <Col xxl={{span: 20, offset: 2}} xl={{span: 20, offset: 2}}>
                                        <div id={"form-wrapper"}>
                                            <Typography.Title level={1} style={{color: "#fff"}}>
                                                Axieinfinity Guest
                                            </Typography.Title>

                                            <Form layout={'vertical'} size={'large'}>
                                                <Row>
                                                    <Col xl={24} lg={24} sm={24} xs={24}>
                                                        <Form.Item label={'Your Ronin Address'}>
                                                            <Input
                                                                value={this.state.roninAddress}
                                                                onChange={event => this.onFieldChange("roninAddress", event.target.value)}
                                                                placeholder={`ronin:xxxx`}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Button onClick={() => this.onClickSearch()} className={'signup-button'}>
                                                            Track
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Layout.Content>
            </Layout>
        );
    }
}

export default Guest;
