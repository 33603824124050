import React from 'react';
import {Route, Routes} from "react-router-dom";
import {Provider} from "react-redux";
import {store} from './redux';
import "./App.less";
import Register from "./screen/Register/Register";
import SignIn from "./screen/SignIn/SignIn";
import Guest from "./screen/Axieinfinity/Guest";
import GuestTracker from "./screen/Axieinfinity/GuestTracker";
import MyNftAssets from "./screen/Axieinfinity/MyNftAssets";
import Dashboard from "./screen/Axieinfinity/Dashboard";
import BattleHistory from "./screen/Axieinfinity/BattleHistory";
import Tracker from "./screen/Axieinfinity/Tracker";

function App() {
    return (
        <Provider store={store}>
            <Routes>
                <Route path={"/"} element={<Guest/>}/>
                <Route path={"/register"} element={<Register/>}/>
                <Route path={"/sign-in"} element={<SignIn/>}/>
                <Route path={"/guest"} element={<Guest/>}/>
                <Route path={"/axieinfinity/guest/tracker/:roninAddress"} element={<GuestTracker/>}/>
                <Route path={"/axieinfinity/my-assets/:roninAddress"} element={<MyNftAssets/>}/>
                <Route path={"/axieinfinity/my-assets"} element={<MyNftAssets/>}/>
                <Route path={"/axieinfinity/battle-history/:roninAddress"} element={<BattleHistory/>}/>
                <Route path={"/axieinfinity/battle-history"} element={<BattleHistory/>}/>
                <Route path={"/axieinfinity/dashboard/:roninAddress"} element={<Dashboard/>}/>
                <Route path={"/axieinfinity/dashboard"} element={<Dashboard/>}/>
                <Route path={"/axieinfinity/tracker/:roninAddress"} element={<Tracker/>}/>
                <Route path={"/axieinfinity/tracker"} element={<Tracker/>}/>
            </Routes>
        </Provider>
    );
}

export default App;
