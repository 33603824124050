import axios from "axios";
import {environmentDevelopment} from "../../config/api/apiConfig";

class AxieInfinityComService {
    private axios = axios.create({
        baseURL: "https://exchange-rate.axieinfinity.com/"
    });

    public async getExchangeRate(params?: { roninAddress?: string }) {
        if(environmentDevelopment){
            // DEV MODE
            return {"axs":{"cny":301.8058,"eur":41.222478,"gbp":34.616487,"jpy":5277.0425,"krw":56592.2347,"myr":191.75154,"php":2485.1109,"sgd":62.09358,"usd":45.59,"vnd":1076777.4448},"usd":{"cny":6.3213,"eur":0.903955,"gbp":0.759127,"jpy":116.05025,"krw":1227.828377,"myr":4.1875,"php":52.211001,"sgd":1.358624,"usd":1.0,"vnd":22855.5},"usdc":{"cny":6.62,"eur":0.9042,"gbp":0.7593,"jpy":115.75,"krw":1241.33,"myr":4.206,"php":54.51,"sgd":1.362,"usd":1.0,"vnd":23618.72},"ron":{"cny":12.03506573,"eur":1.72103172,"gbp":1.44529501,"jpy":220.9470183,"krw":2337.65130954,"myr":7.97254327,"php":99.40405121,"sgd":2.58667191,"usd":1.90389093,"vnd":43514.37913158},"slp":{"cny":0.11550076,"eur":0.0157758,"gbp":0.01324769,"jpy":2.01951863,"krw":21.65778888,"myr":0.07338311,"php":0.95104934,"sgd":0.02376315,"usd":0.01744725,"vnd":412.08159915},"eth":{"cny":17035.5108,"eur":2326.814028,"gbp":1953.937062,"jpy":297864.105,"krw":3194364.1422,"myr":10823.46804,"php":140272.7634,"sgd":3504.88908,"usd":2573.34,"vnd":60778996.92480001}};
        }

        try {
            const data = await this.axios.get<any>(``, {
                headers: {},
                method: 'get',
            });

            return data?.data;

        } catch (e: any) {
            console.log(e)
            throw new Error(e);
        }
    }
}


export const axieInfinityComService = new AxieInfinityComService();
