import axios from "axios";
import {environmentDevelopment} from "../../config/api/apiConfig";

class RoninChainService {
    private axios = axios.create({
        baseURL: "https://asia-southeast1-invento-nft.cloudfunctions.net/apin3ftgames__tokenHoldings"
    });

    public async getTokenHoldings(params?: { roninAddress?: string }) {
        if (environmentDevelopment) {
            // DEV MODE
            return {
                "data": [{"token_code": "AXIE", "token_type": "ERC721", "token_name": "axie", "market_name": "Axie", "token_amount": 10}, {"token_code": "AXS", "token_type": "ERC20", "token_name": "axie infinity shard", "market_name": "Axie Infinity Shard (AXS)", "token_amount": 0.00121547}, {"token_code": "SLP", "token_type": "ERC20", "token_name": "smooth love potion", "market_name": "Smooth Love Potion (SLP)", "token_amount": 7}, {
                    "token_code": "USDC",
                    "token_type": "ERC20",
                    "token_name": "usd coin",
                    "market_name": "USD Coin (USDC)",
                    "token_amount": 0.207999
                }, {"token_code": "WETH", "token_type": "ERC20", "token_name": "ronin wrapped ether", "market_name": "Ronin Wrapped Ether (WETH)", "token_amount": 0.0000169154}, {"token_code": "RON", "token_type": "ERC20", "token_name": "ron", "market_name": "RON", "token_amount": 0.082336}]
            };
        }

        try {
            const dataChain = await this.axios.get<any>(`/axieinfinity/token-holdings/${params?.roninAddress}`, {
                headers: {
                    // 'Access-Control-Allow-Origin': '*',
                    // 'Content-Type': 'application/json',
                    // 'authority': "game-api.skymavis.com",
                },
                method: 'get',
            });
            return dataChain?.data;
        } catch (e: any) {
            console.log(e)
            throw new Error(e);
        }
    }

    public async getAccountTracking(params?: { roninAddress?: string }) {
        try {
            const dataChain = await this.axios.get<any>(`/axieinfinity/account-tracking/${params?.roninAddress}`, {
                headers: {},
                method: 'get',
            });
            return dataChain?.data;
        } catch (e: any) {
            console.log(e)
            throw new Error(e);
        }
    }
}


export const roninChainService = new RoninChainService();
