import React, {Component} from 'react';
import {withRouter, WithRouterProps} from "../../hoc/withRouter";
import Form from "antd/lib/form";
import MainLayout from "../../components/MainLayout/MainLayout";
import "./Axieinfinity.css";
import {Button, Col, Input, InputNumber, Modal, Row, Table} from "antd";
import {EditOutlined} from "@ant-design/icons";
import {roninChainService} from "../../services/axieinfinity/RoninChainService";
import moment from "moment";
import numberFormat from "../../helper/numberFormat";
import {TrackerResume} from "../../components/Tracker/TrackerResume";

interface IParams {
    roninAddress: string;
}

interface ITrackerStates {
    processing: boolean;
    loadingTable: boolean;
    dataScholars: any;

    tempRoninAddress: string;
    tempScholarName: string;
    tempManagerPercentage: number;
    tempScholarPercentage: number;

    modalChangeAddress: boolean;
}

type Props = WithRouterProps<IParams>;

class Tracker extends Component<Props, ITrackerStates> {
    private readonly initState!: ITrackerStates;
    private tableColumn: any = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Axies',
            dataIndex: 'axies',
            sorter: (a: { age: number; }, b: { age: number; }) => a.age - b.age,
        },
        {
            title: 'Elo',
            dataIndex: 'elo',
            align: "right",
            sorter: (a: { age: number; }, b: { age: number; }) => a.age - b.age,
            render: ((value: number, row: any) => {
                return numberFormat(value);
            })
        },
        {
            title: 'Rank',
            dataIndex: 'rank',
            align: "right",
            sorter: (a: { age: number; }, b: { age: number; }) => a.age - b.age,
            render: ((value: number, row: any) => {
                return numberFormat(value);
            })
        },
        {
            title: 'In Game SLP',
            dataIndex: 'inGameSlp',
            align: "right",
            sorter: (a: { age: number; }, b: { age: number; }) => a.age - b.age,
            render: ((value: number, row: any) => {
                return numberFormat(value);
            })
        },
        {
            title: 'Scholar',
            dataIndex: 'scholarSlp',
            align: "right",
            sorter: (a: { age: number; }, b: { age: number; }) => a.age - b.age,
            render: ((value: number, row: any) => {
                return numberFormat(value);
            })
        },
        {
            title: 'Manager',
            dataIndex: 'managerSlp',
            align: "right",
            sorter: (a: { age: number; }, b: { age: number; }) => a.age - b.age,
            render: ((value: number, row: any) => {
                return numberFormat(value);
            })
        },
        {
            title: 'Next Claim SLP',
            dataIndex: 'nextClaimSlp',
        },
        {
            title: 'LifeTime',
            dataIndex: 'lifetimeSlp',
            align: "right",
            sorter: (a: { age: number; }, b: { age: number; }) => a.age - b.age,
            render: ((value: number, row: any) => {
                return numberFormat(value);
            })
        },
    ];

    constructor(props: any) {
        super(props);

        this.initState = {
            processing: true,
            loadingTable: false,
            dataScholars: [],
            tempRoninAddress: "ronin:6462ce6073437557ef1d28b0f64664a99b4220da",
            tempScholarName: "Test Name",
            tempManagerPercentage: 50,
            tempScholarPercentage: 50,
            modalChangeAddress: false,
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        document.title = "N3FT.GAMES - Monitor Guild";
        this.getScholarsData();
    }

    async getScholarsData() {
        this.setState({
            loadingTable: true,
        });

        let aaa = localStorage.getItem('dataTracker');
        let dataTrackerTemp: any = (aaa) ? JSON.parse(aaa) : [];

        const listRoninAccounts = [];
        const dataRoninStorage: any = {};
        const dataScholar:any = [];
        for (const item of dataTrackerTemp) {
            listRoninAccounts.push(item?.wallet_address);
            dataRoninStorage[item?.wallet_address] = item;
        }

        await Promise.all(listRoninAccounts.map(async (a) => {
            const dataAccount = await roninChainService.getAccountTracking({roninAddress: a});

            let scholarSlp = 0;
            let managerSlp = 0;
            if(dataAccount?.data?.in_game_slp > 0){
                scholarSlp = Math.floor(dataRoninStorage[dataAccount?.ronin_address]?.scholar_percentage / 100 * dataAccount?.data?.in_game_slp);
                managerSlp = dataAccount?.data?.in_game_slp - scholarSlp;
            }

            dataScholar.push({
                name: dataRoninStorage[dataAccount?.ronin_address]?.scholar_name,
                axies: dataAccount?.data?.axie,
                elo: dataAccount?.data?.mmr,
                rank: dataAccount?.data?.elo,
                inGameSlp: dataAccount?.data?.in_game_slp,
                nextClaimSlp: (dataAccount?.data?.next_claim < 1500000 ) ? "-" : moment.unix(dataAccount?.data?.next_claim).format('MMMM Do, YYYY'),
                scholarSlp: scholarSlp,
                managerSlp: managerSlp,
                lifetimeSlp: dataAccount?.data?.lifetime_slp,
                roninSlp: dataAccount?.data?.ronin_slp,
            });
        }));

        this.setState({
            dataScholars: dataScholar,
            loadingTable: false,
        });
    }


    onFieldChange = async <T extends keyof Pick<any, "tempRoninAddress" | "tempScholarName" | "tempManagerPercentage">>(target: T, value: any) => {
        const currentState = {...this.state};
        switch (target) {
            case "tempRoninAddress":
                currentState.tempRoninAddress = value;
                break;
            case "tempScholarName":
                currentState.tempScholarName = value;
                break;
            case "tempManagerPercentage":
                if (value > 100) {
                    value = 100;
                }
                currentState.tempManagerPercentage = value;
                currentState.tempScholarPercentage = 100 - value;
                break;
        }

        await this.setState({
            ...currentState,
        });
    }

    openModalChangeAddress = () => {
        this.setState({
            modalChangeAddress: true,
        });
    }

    closeModalChangeAddress = () => {
        this.setState({
            modalChangeAddress: false,
        });
    }

    buttonAddAddress = async () => {
        if (!this.state.tempRoninAddress || this.state.tempRoninAddress.length !== 46 || (this.state.tempRoninAddress.substring(0, 6) !== "ronin:")) {
            Modal.error({
                title: 'Error',
                content: 'Please input valid ronin address'
            });
            return;
        }

        if (!this.state.tempScholarName) {
            Modal.error({
                title: 'Error',
                content: 'Please input scholar name'
            });
            return;
        }

        if (!this.state.tempManagerPercentage) {
            Modal.error({
                title: 'Error',
                content: 'Please input Manager percentage'
            });
            return;
        }

        let aaa = localStorage.getItem('dataTracker');
        let dataTrackerTemp: any = (aaa) ? JSON.parse(aaa) : [];
        const dataTracker: any = [...dataTrackerTemp];

        for (const element of dataTracker) {
            if (element.wallet_address === this.state.tempRoninAddress) {
                Modal.error({
                    title: 'Error',
                    content: 'Ronin Address exist'
                });
                return;
            }
        }

        dataTracker.push({
            wallet_address: this.state.tempRoninAddress,
            scholar_name: this.state.tempScholarName,
            manager_percentage: this.state.tempManagerPercentage,
            scholar_percentage: this.state.tempScholarPercentage,
            investor_percentage: 0,
            scholar_wallet_address: "",
            investor_wallet_address: "",
        });
        localStorage.setItem('dataTracker', JSON.stringify(dataTracker));

        await this.setState({
            modalChangeAddress: false,
            tempRoninAddress: "",
            tempScholarName: "",
            tempManagerPercentage: 50,
            tempScholarPercentage: 50,
        });

        this.getScholarsData();
    }

    onChange = async (pagination: any, filters: any, sorter: any, extra: any) => {
        console.log('params', pagination, filters, sorter, extra);
    }

    render() {
        return (
            <React.Fragment>
                <MainLayout defaultSelectedKeys={['m-tracker']} roninAddress={""}>

                    <br/><span className={`h2Title`}>Monitor Guild</span> <span onClick={this.openModalChangeAddress} className={`roninwalletEditable`}> Add New Account <EditOutlined/> </span> <br/><br/>

                    <Row style={{marginTop: 14}}>

                        <TrackerResume data={this.state.dataScholars}/>

                        <Col xs={24} xl={24} style={{marginTop: 20}}>
                            <Table
                                columns={this.tableColumn}
                                loading={this.state.loadingTable}
                                dataSource={this.state.dataScholars}
                                onChange={this.onChange}
                                rowKey={'name'}
                            />
                        </Col>
                    </Row>
                </MainLayout>

                <Modal
                    visible={this.state.modalChangeAddress}
                    title={`Add Scholar Account`}
                    onOk={this.closeModalChangeAddress}
                    onCancel={this.closeModalChangeAddress}
                    // width={'80%'}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button key="1" type="primary" onClick={this.buttonAddAddress}>Add</Button>,
                        <Button key="2" type="primary" onClick={this.closeModalChangeAddress}>Close</Button>,
                    ]}
                >
                    <Form.Item>
                        <div className={`text-small-grey`}>Scholar Name</div>
                        <Input
                            value={this.state.tempScholarName}
                            placeholder="Scholar / Acount Name"
                            onChange={event => this.onFieldChange("tempScholarName", event.target.value)}
                        />
                    </Form.Item>
                    <Form.Item>
                        <div className={`text-small-grey`}>Ronin Address</div>
                        <Input
                            value={this.state.tempRoninAddress}
                            placeholder="Ronin:xx"
                            onChange={event => this.onFieldChange("tempRoninAddress", event.target.value)}
                        />
                    </Form.Item>
                    <Form.Item>
                        <div className={`text-small-grey`}>Manager percentage</div>
                        <InputNumber
                            style={{width: "100%"}}
                            value={this.state.tempManagerPercentage}
                            placeholder="Manager percentage"
                            onChange={event => this.onFieldChange("tempManagerPercentage", event)}
                        />
                    </Form.Item>
                    <Form.Item>
                        <div className={`text-small-grey`}>Scholar Percentage</div>
                        <InputNumber
                            style={{width: "100%"}}
                            disabled={true}
                            value={this.state.tempScholarPercentage}
                            placeholder="Scholar Percentage"
                        />
                    </Form.Item>
                </Modal>

            </React.Fragment>
        );
    }
}

export default withRouter(Tracker);
