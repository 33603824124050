import axios from "axios";
import {environmentDevelopment} from "../../config/api/apiConfig";

class EloService {
    private axios = axios.create({
        baseURL: "https://game-api.axie.technology/"
    });

    public async getElo(params?: { roninAddress?: string }) {

        if (environmentDevelopment) {
            // DEV MODE
            return [{"items":[{"name":"","elo":1373,"rank":212520,"client_id":"0x6462ce6073437557ef1d28b0f64664a99b4220da"},{"name":"Mobile666 | Team Unko","elo":2322,"rank":1,"client_id":"0x3c23233432998339088f0afb7f28c67dc74e61ad"}],"success":true,"update_time":1648344187690}];
        }

        try {
            const data = await this.axios.get<any>(`mmr/${params?.roninAddress?.replace('ronin:', '0x')}`, {
                headers: {},
                method: 'get',
            });

            return data?.data;

        } catch (e: any) {
            console.log(e)
            throw new Error(e);
        }
    }
}


export const eloService = new EloService();
