import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Button, Checkbox, Col, Divider, Form, Input, Layout, Modal, Row, Select, Typography} from "antd";
import "./Register.less";
import {countryList} from "../../config/data/countryData";
import {authServices} from "../../services/n3ft/AuthService";

const {Option} = Select;

interface IRegister {
    processing: boolean;
    accountCountry: string | null;
    accountName: string;
    accountEmail: string;
    accountPassword: string;
    accountReTypePassword: string;
    accountPhoneNumber: string;
    prefixPhoneNumber: string | null;
}

class Register extends Component<any, IRegister> {
    private readonly initState!: IRegister;

    constructor(props: any) {
        super(props);

        this.initState = {
            processing: false,
            accountCountry: null,
            accountName: "",
            accountEmail: "",
            accountPassword: "",
            accountReTypePassword: "",
            accountPhoneNumber: "",
            prefixPhoneNumber: null,
        }
        this.state = {...this.initState}
    }

    onFieldChange = <T extends keyof Pick<any, "accountCountry" | "accountName" | "accountEmail" | "accountPassword" | "accountReTypePassword" | "accountPhoneNumber">>(target: T, value: string) => {
        const currentState = {...this.state};
        switch (target) {
            case "accountCountry":
                currentState.accountCountry = value;
                const words = value.split('__');
                currentState.prefixPhoneNumber = "+" + words[1] + " ";
                break;
            case "accountName":
                currentState.accountName = value;
                break;
            case "accountEmail":
                currentState.accountEmail = value;
                break;
            case "accountPassword":
                currentState.accountPassword = value;
                break;
            case "accountReTypePassword":
                currentState.accountReTypePassword = value;
                break;
            case "accountPhoneNumber":
                currentState.accountPhoneNumber = value;
                break;
        }

        this.setState({
            ...currentState,
        });
    }

    onClickRegister = async () => {

        if (!this.state.accountCountry || !this.state.accountName || !this.state.accountEmail || !this.state.accountPassword || !this.state.accountReTypePassword || !this.state.accountPhoneNumber) {
            Modal.error({
                title: 'Error',
                content: "Please complate all field"
            });
            return;
        }

        if (this.state.accountPassword !== this.state.accountReTypePassword) {
            Modal.error({
                title: 'Error',
                content: "your password and retype password not match"
            });
            return;
        }

        this.setState({
            processing: true,
        });

        try {
            await authServices.register({
                "guild_name": "AAA",
                "owner_name": "doni owner",
                "owner_email": "doni@test.co.id",
                "owner_phone_number": "6281222810904",
                "owner_birth_date": "1994-02-28",
                "guild_country": "ID",
            }).then(successData => {
                window.location.href = `/spk/${successData.data.data.offer_code}`;
            });
        } catch (e: any) {
            Modal.error({
                title: 'Error',
                content: e?.toString()
            });
            await this.setState({});
        }


    }

    render() {
        return (
            <Layout>
                <Layout.Content>
                    <div id={"sign-up-body"}>
                        <Row id="sign-up-wrapper">
                            <Col xl={12} lg={12} md={24} sm={24} xs={24} id={"sign-up-left-side"}>

                                <Link to={`/`}>
                                    <img className={`header-logo`} src={'../assets/img/n3ftlogo.png'} alt={`n3ft-logo`}/>
                                </Link>

                                <div id={"text-left-side-wrapper"}>
                                    <Typography.Title level={1}>
                                        Let's create something amazing work with Us.
                                    </Typography.Title>
                                </div>
                            </Col>
                            <Col xl={12} lg={12} md={24} sm={24} xs={24} id={"sign-up-right-side"}>
                                <div id={"sign-in-link"}>
                                    <Typography.Text style={{color: "#7A86A1"}}>Already have account?
                                        <Link to={`/sign-in`}> Login here</Link>
                                    </Typography.Text>
                                </div>
                                <div id={"form-register-wrapper"}>
                                    <Typography.Title level={2}>
                                        Create Your Account
                                    </Typography.Title>
                                    <Divider style={{
                                        borderTop: "1px solid rgba(255,255,255, .5)",
                                        minWidth: "auto",
                                        width: "50px"
                                    }}/>
                                    <Form layout={'vertical'} size={'large'}>
                                        <Row gutter={[16, 0]}>
                                            <Col xl={12} lg={12} sm={12} xs={24}>
                                                <Form.Item label={'Name'}>
                                                    <Input value={this.state.accountName} onChange={event => this.onFieldChange("accountName", event.target.value)} placeholder="your name"/>
                                                </Form.Item>
                                            </Col>
                                            <Col xl={12} lg={12} sm={12} xs={24}>
                                                <Form.Item label={'Email'}>
                                                    <Input value={this.state.accountEmail} onChange={event => this.onFieldChange("accountEmail", event.target.value)} placeholder="your email"/>
                                                </Form.Item>
                                            </Col>
                                            <Col xl={12} lg={12} sm={12} xs={24}>
                                                <Form.Item label={'Country'}>
                                                    <Select
                                                        showSearch
                                                        value={this.state.accountCountry}
                                                        onChange={value => this.onFieldChange("accountCountry", value)}
                                                        placeholder={`select your country`}
                                                    >
                                                        {
                                                            countryList.map((item: any, i: number) =>
                                                                <Select.Option key={i} value={item?.countryName + "__" + item?.countryCode}>{item?.countryName}</Select.Option>
                                                            )
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xl={12} lg={12} sm={12} xs={24}>
                                                <Form.Item label={'Phone Number'}>
                                                    <Input prefix={this.state.prefixPhoneNumber} value={this.state.accountPhoneNumber} onChange={event => this.onFieldChange("accountPhoneNumber", event.target.value)} placeholder="your phone number"/>
                                                </Form.Item>
                                            </Col>
                                            <Col xl={12} lg={12} sm={12} xs={24}>
                                                <Form.Item label={'Password'}>
                                                    <Input.Password value={this.state.accountPassword} onChange={event => this.onFieldChange("accountPassword", event.target.value)} placeholder="your password"/>
                                                </Form.Item>
                                            </Col>
                                            <Col xl={12} lg={12} sm={12} xs={24}>
                                                <Form.Item label={'Re Type Password'}>
                                                    <Input.Password value={this.state.accountReTypePassword} onChange={event => this.onFieldChange("accountReTypePassword", event.target.value)} placeholder="retype your password"/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Checkbox>I accept <Typography.Link>Terms and
                                                    Conditions</Typography.Link></Checkbox>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button className={'signup-button'}
                                                        loading={this.state.processing}
                                                        onClick={() => this.onClickRegister()}
                                                >
                                                    Sign Up
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Layout.Content>
            </Layout>
        );
    }
}

export default Register;
