import React, {FunctionComponent} from "react";
import {Col, Row, Tag} from "antd";
import currencyFormat from "../../helper/currencyFormat";

export const TokenHoldingVertical: FunctionComponent<any> = (props: any) => {
    const objectToken: any = {};
    for (const item of props?.tokenData) {
        objectToken[item?.token_code] = item
    }

    const ronValue = objectToken['RON']?.token_amount ?? 0;
    const axsValue = objectToken['AXS']?.token_amount ?? 0;
    const slpValue = objectToken['SLP']?.token_amount ?? 0;
    const usdcValue = objectToken['USDC']?.token_amount ?? 0;
    const wethValue = objectToken['WETH']?.token_amount ?? 0;
    const axieValue = objectToken['AXIE']?.token_amount ?? 0;
    const landValue = objectToken['LAND']?.token_amount ?? 0;
    const landItemValue = objectToken['LAND-ITEM']?.token_amount ?? 0;

    const exchangeRonUsd = props.dataExchangerate?.ron?.usd ?? null;
    const exchangeAxsUsd = props.dataExchangerate?.axs?.usd ?? null;
    const exchangeSlpUsd = props.dataExchangerate?.slp?.usd ?? null;
    const exchangeEthUsd = props.dataExchangerate?.eth?.usd ?? null;

    return (
        <div className={`boxListProduct`}>
            <div className={`insideBoxListProduct`}>
                <Row>
                    <Col span={7}>
                        <img className={`boxNftLogo`} src={'../../assets/axieinfinity/ron.png'} alt={`n3ft-logo`}/>
                    </Col>
                    <Col span={17}>
                        <span className={`nftValue`}> {ronValue} RON</span><br/>
                        <span className={`nftValueConvert`}>~ {(exchangeRonUsd) ? currencyFormat((ronValue * exchangeRonUsd)) : "-" } USD</span>
                    </Col>
                </Row>
            </div>
            <div className={`insideBoxListProduct`}>
                <Row>
                    <Col span={7}>
                        <img className={`boxNftLogo`} src={'../../assets/axieinfinity/axs.png'} alt={`n3ft-logo`}/>
                    </Col>
                    <Col span={17}>
                        <span className={`nftValue`}> {axsValue} AXS</span><br/>
                        <span className={`nftValueConvert`}>~ {(exchangeAxsUsd) ? currencyFormat((axsValue * exchangeAxsUsd)) : "-" } USD</span>
                    </Col>
                </Row>
            </div>
            <div className={`insideBoxListProduct`}>
                <Row>
                    <Col span={7}>
                        <img className={`boxNftLogo`} src={'../../assets/axieinfinity/slp.png'} alt={`n3ft-logo`}/>
                    </Col>
                    <Col span={17}>
                        <span className={`nftValue`}> {slpValue} SLP</span><br/>
                        <span className={`nftValueConvert`}>~ {(exchangeSlpUsd) ? currencyFormat((slpValue * exchangeSlpUsd)) : "-" } USD</span>
                    </Col>
                </Row>
            </div>
            <div className={`insideBoxListProduct`}>
                <Row>
                    <Col span={7}>
                        <img className={`boxNftLogo`} src={'../../assets/axieinfinity/eth.png'} alt={`n3ft-logo`}/>
                    </Col>
                    <Col span={17}>
                        <span className={`nftValue`}> {wethValue} WETH</span><br/>
                        <span className={`nftValueConvert`}>~ {(exchangeEthUsd) ? currencyFormat((wethValue * exchangeEthUsd)) : "-" } USD</span>
                    </Col>
                </Row>
            </div>
            <div className={`insideBoxListProduct`}>
                <Row>
                    <Col span={7}>
                        <img className={`boxNftLogo`} src={'../../assets/axieinfinity/usdc.png'} alt={`n3ft-logo`}/>
                    </Col>
                    <Col span={17}>
                        <span className={`nftValue`}> {usdcValue} USDC</span><br/>
                        <span className={`nftValueConvert`}>-</span>
                    </Col>
                </Row>
            </div>
            <div className={`insideBoxListProduct`}>
                <Row>
                    <Col span={7}>
                        <img className={`boxNftLogo`} src={'../../assets/axieinfinity/axie.png'} alt={`n3ft-logo`}/>
                    </Col>
                    <Col span={17}>
                        <span className={`nftValue`}> {axieValue} AXIE</span><br/>
                        <span className={`nftValueConvert`}>-</span>
                    </Col>
                </Row>
            </div>
            <div className={`insideBoxListProduct`}>
                <Row>
                    <Col span={7}>
                        <img className={`boxNftLogo`} src={'../../assets/axieinfinity/land.png'} alt={`n3ft-logo`}/>
                    </Col>
                    <Col span={17}>
                        <span className={`nftValue`}> {landValue} LAND</span><br/>
                        <span className={`nftValueConvert`}>-</span>
                    </Col>
                </Row>
            </div>
            <div className={`insideBoxListProduct`}>
                <Row>
                    <Col span={7}>
                        <img className={`boxNftLogo`} src={'../../assets/axieinfinity/item.png'} alt={`n3ft-logo`}/>
                    </Col>
                    <Col span={17}>
                        <span className={`nftValue`}> {landItemValue} ITEM</span><br/>
                        <span className={`nftValueConvert`}>-</span>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
