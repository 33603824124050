import React, {Component} from 'react';
import {Button, Col, Drawer, Menu, Row} from 'antd';
import "./MainLayout.css";
import {MenuOutlined} from "@ant-design/icons";
import {Content} from "antd/es/layout/layout";
import {Link} from "react-router-dom";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class MainLayout extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            collapsed: false,
            viewClass: 'desktop',
            user: "",
            current: 'mail',
            visible: false
        }
        this.state = {...this.initState}
    }

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };
    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    async componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {
        return (
            <React.Fragment>
                <div className={`divMenuDesktop`}>
                    <Row>
                        <Col span={12} offset={2}>
                            <Menu mode="horizontal" theme="dark">
                                <Menu.Item key="Logo">
                                    <Link to={`/`}> <img className={`header-logo`} src={'../../assets/img/n3ftlogo.png'} alt={`n3ft-logo`}/> </Link>
                                </Menu.Item>
                                <Menu.Item key={`dashboard`}>
                                    <Link to={`/axieinfinity/dashboard/${this.props?.roninAddress}`}> Dashborad </Link>
                                </Menu.Item>
                                <SubMenu key={`sub-1`} title={<span>Games</span>}>
                                    <MenuItemGroup key={`group-1`} title="Axieinfinity">
                                        <Menu.Item key="key-1">
                                            <Link to={`/axieinfinity/my-assets/${this.props?.roninAddress}`}> My Assets </Link>
                                        </Menu.Item>
                                        <Menu.Item key="key-2">
                                            <Link to={`/axieinfinity/battle-history/${this.props?.roninAddress}`}> Battle History </Link>
                                        </Menu.Item>
                                        <Menu.Item key="key-3">
                                            <Link to={`/axieinfinity/tracker/${this.props?.roninAddress}`}> Monitor Guild </Link>
                                        </Menu.Item>
                                    </MenuItemGroup>
                                </SubMenu>
                            </Menu>
                        </Col>
                    </Row>
                </div>

                <div className={`divMenuMobile`}>
                    <Row>
                        <Col span={12} offset={2}>
                            <Menu mode="horizontal" theme="dark">
                                <Menu.Item key="Logo">
                                    <img className={`header-logo`} src={'../../assets/img/n3ftlogo.png'} alt={`n3ft-logo`}/>
                                </Menu.Item>
                            </Menu>
                        </Col>
                        <Col span={8}>
                            <div className={`floatRight`} style={{paddingTop: "7px"}}>
                                <Button onClick={this.showDrawer} type="default" icon={<MenuOutlined/>}/>
                            </div>
                        </Col>
                    </Row>
                    <Drawer
                        title="Menu"
                        placement="right"
                        closable={false}
                        onClose={this.onClose}
                        visible={this.state.visible}
                    >
                        <div>
                            <Menu
                                defaultSelectedKeys={['1']}
                                defaultOpenKeys={['sub1']}
                                mode="inline"
                                theme="dark"
                                inlineCollapsed={this.state.collapsed}
                            >
                                <Menu.Item key="1">
                                    Menu 1
                                </Menu.Item>
                            </Menu>
                        </div>
                    </Drawer>
                </div>

                <Content className={`mainContent`} style={{minHeight: '100vh'}}>
                    <Row>
                        <Col xxl={{span: 17, offset: 4}} xl={{span: 20, offset: 2}} lg={{span: 24, offset: 0}} md={{span: 24, offset: 0}} sm={{span: 24, offset: 0}} xs={{span: 24, offset: 0}}>
                            <div>{this.props.children}</div>
                        </Col>
                    </Row>
                </Content>
            </React.Fragment>
        );
    }
}

export default MainLayout;

